import React from 'react';

const Services = () => {
  return (
    <div>
      <h1>Our Services</h1>
      <ul>
        <li>Service 1</li>
        <li>Service 2</li>
       <li>Service 3</li>
      </ul>
    </div>
  );
};

export default React.memo(Services);
