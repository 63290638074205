import React from 'react';
import About1 from './About/Abouttitle';

const AboutUs = () => {
  return (
    <div>
        <About1/>
    </div>
  );
};

export default React.memo(AboutUs);
